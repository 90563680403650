






















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { PayloadState } from '@/types/types';

@Component({
  name: 'DeleteDialog',
})
export default class DeleteDialog extends Vue {
  @Prop({ required: true, default: false }) state!: boolean;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ required: true }) message!: string;
  @Prop({ required: false }) primaryKey!: string;

  @Emit('actions')
  close(state: PayloadState) {
    return state;
  }

  @Emit('delete')
  action() {
    return {
      loading: {
        state: false,
        idx: 'delete',
      },
      id: this.primaryKey,
    };
  }
}
